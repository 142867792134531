export const baseURL = 'https://dev-api.gowifmi.com'
export const googleMapApiKey = 'AIzaSyBR3rAE5g4lkE5Mc7pnU-z9YkQRMdAfaOc'
export const googleGeocodingApiKey = 'AIzaSyCHcX984U239QlES-iIv7zs4_qiRDEAv5I'
export const googleAuthClienId =
  '871041220070-7ob7fofrr493fign93oi964c75spd88k.apps.googleusercontent.com'
export const facebookAppId = '1344823559672241'
export const stripeApiKey =
  'pk_test_51OQ1sYCqvk3gjs8HpdkeuMUWhFgMw0q2u2I4jTTaDTa3O7laTTfPBpKqfcudTVa4ZClQXSMpzl2Aq1tF9TQbuVVd00UUqHL2UK'
export const crispWebsiteId = 'd958c524-c1c4-47be-a7e4-3e8e2e5709d7'
export const freeModeMonths = '3'
export const freeModeDays = '90'
export const hostServiceFee = '3 %'
