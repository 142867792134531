import { useState } from 'react'
import styles from '../../../../css/modehotes/inbox/detailMessageHote.module.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Tooltip from '../../../ui/Tooltip'

const DeleteSvg = () => {
  return (
    <svg
      style={{
        display: 'block',
        height: '16px',
        width: '16px',
      }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      aria-hidden='true'
      role='presentation'
      focusable='false'
      fill='currentcolor'
    >
      <path d='M10 .38c.31 0 .57.23.62.53V2.5H14v2h-1V14a1 1 0 0 1-.88 1H4a1 1 0 0 1-1-.88V4.5H2v-2h3.38V1c0-.31.23-.57.53-.62H10zM6.12 4.5H4.88v9h1.25v-9zm2.5 0H7.38v9h1.25v-9zm2.5 0H9.88v9h1.24v-9zM9.38 1.62H6.62v.88h2.75v-.87z'></path>
    </svg>
  )
}

const Header = ({ path, filter, setModalLeftbar, conversation }) => {
  const [addToarchive, setAddToarchive] = useState(false)
  const [deleteToarchive, setDeleteToarchive] = useState(false)
  const history = useHistory()

  const ArchiveMessage = () => {
    axios
      .patch(`/hotemessage/archiveMessage/${path}`)
      .then(() => {
        setAddToarchive(true)
        setDeleteToarchive(false)
      })
      .catch(() => {})
    setTimeout(() => {
      setAddToarchive(false)
      history.push('/hosting/inbox/all')
    }, 2000)
  }
  const RetirerarchiveMessage = () => {
    axios
      .patch(`/hotemessage/retirearchiveMessage/${path}`)
      .then(() => {
        setDeleteToarchive(true)
        setAddToarchive(false)
      })
      .catch(() => {})
    setTimeout(() => {
      setDeleteToarchive(false)
      history.push('/hosting/inbox/hidden')
    }, 2000)
  }
  return (
    <>
      <div className={styles.head}>
        <div className={styles.left}>
          <button type='button' onClick={() => setModalLeftbar(true)}>
            <svg
              viewBox='0 0 32 32'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
              role='presentation'
              focusable='false'
              style={{
                display: 'block',
                fill: 'none',
                height: '16px',
                width: '16px',
                stroke: 'currentColor',
                strokeWidth: '3',
                overflow: 'visible',
              }}
            >
              <g fill='none'>
                <path d='M2 16h28M2 24h28M2 8h28'></path>
              </g>
            </svg>
          </button>
          {filter === 'all' && <h2>Tous les messages</h2>}
          {filter === 'hidden' && <h2>Messages archivés</h2>}
        </div>
        <div className={styles.buttons}>
          {conversation?.host_archived === false ? (
            <Tooltip title='Archiver'>
              <button onClick={ArchiveMessage} className={styles.delete}>
                <DeleteSvg />
              </button>
            </Tooltip>
          ) : (
            <Tooltip title='Retirer des archives'>
              <button onClick={RetirerarchiveMessage} className={styles.delete}>
                <DeleteSvg />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      {/*********------modal archive message----- */}
      <div
        className={
          addToarchive ? `${styles.modal_Snackbar} ${styles.active}` : styles.modal_Snackbar
        }
      >
        <DeleteSvg />
        <p>Conversation archivée</p>
      </div>
      <div
        className={
          deleteToarchive ? `${styles.modal_Snackbar} ${styles.active}` : styles.modal_Snackbar
        }
      >
        <DeleteSvg />
        <p>Conversation retirée des archives</p>
      </div>
    </>
  )
}

export default Header
